<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text text-bold">智能停车</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text"></span>
            </div>
            <div class="react-left bg-color-blue ml-3">
              <span class="text"></span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b"></span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box mt-20">
            <div>
              <dv-border-box-12>
                <centerLeft1 title="车场" :num="parkInfo.parkNum" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft1 title="通道" :num="parkInfo.guardNum" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft1 title="在场车辆" :num="parkInfo.carNum" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft1 title="月租车" :num="parkInfo.vipNum" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft1 title="商家" :num="parkInfo.merchantNum" />
              </dv-border-box-12>
            </div>
          </div>

          <div class="content-box-1 mt-30">
            <div class="flexs flex-col">
              <dv-border-box-1>
                <bottomLeft />
              </dv-border-box-1>
              <dv-border-box-8 class="mt-10">
                <center />
              </dv-border-box-8>
            </div>
            <div>
              <dv-border-box-13 :reverse="true">
                <centerRight1 />
              </dv-border-box-13>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './centerLeft1'
import centerRight1 from './centerRight1'
import center from './center'
import bottomLeft from './bottomLeft'

import {
  getNotice
} from '../utils/API/home.js'
export default {
  mixins: [ drawMixin ],
  components: {
    centerLeft1,
    centerRight1,
    center,
    bottomLeft,
  },
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],

      // 车场参数
      // parkIds:[28, 30, 31, 32, 33, 34, 35, 38, 39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,],

      parkIds:[157,155,147],

      parkInfo:{}, // 车场信息


    }
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
    this.getNotice()
    localStorage.setItem('parkIds',JSON.stringify(this.parkIds) )
    this.parkIds = localStorage.getItem("parkIds")

  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取车场、通道、商家数据
    getNotice() {
      console.log('获取临时支付渠道数据')
      getNotice(this.parkIds).then(res => {
        if (res.data) {
          this.parkInfo = res.data
          console.log('this.parkInfo',this.parkInfo)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
