import Vue from 'vue'
// axios
import axios from 'axios'
import router from '@/router'

const axiosIns = axios.create({

  // ---------------beat版-------------------------------
  // baseURL: 'http://gateway.chengyipark.com',

  // ---------------稳定版-------------------------------
  baseURL: 'https://gw.chengyipark.com',

  timeout: 1000000,
})

axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = localStorage.getItem('111111111111')
    // eslint-disable-next-line no-param-reassign
    config.headers['Access-Control-Allow-Origin'] = '*'
    // eslint-disable-next-line no-param-reassign
    config.headers['Access-Control-Allow-Headers'] = 'Authorization,Origin, X-Requested-With, Content-Type, Accept'
    // eslint-disable-next-line no-param-reassign
    config.headers['Access-Control-Allow-Methods'] = '*'
    // eslint-disable-next-line no-param-reassign
    config.headers.withCredentials = true
    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${99999999} ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error),
)
// Add request/response interceptor
axiosIns.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  response => {
    if (response.headers) {
      const contentType = response.headers['content-type']
      if (response.status === 200 && contentType.indexOf('octet-stream') >= 0) {
        return response
      }
    }
    const res = response.data
    if (res.code !== 0) {
      // this.$toast(res.message, { type: TYPE.ERROR })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 100002) {
        // to re-login
        // this.$bvModal.msgBoxConfirm('登录失效，请重新登录', {
        //   title: '提示',
        //   size: 'sm',
        //   okTitle: '退出系统',
        //   cancelTitle: '取消',
        //   hideHeaderClose: true,
        //   centered: false,
        // }).then(() => {
        //   window.location.reload()
        // })
        window.location.reload()

      }
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    // const { config, response: { status } } = error
    // const { config, response } = error
    // const originalRequest = config

    // if (status === 401) {
    // if (response && response.status === 401) {
    //   if (!this.isAlreadyFetchingAccessToken) {
    //     this.isAlreadyFetchingAccessToken = true
    //     this.refreshToken().then(r => {
    //       this.isAlreadyFetchingAccessToken = false
    //
    //       // Update accessToken in localStorage
    //       this.setToken(r.data.accessToken)
    //       this.setRefreshToken(r.data.refreshToken)
    //
    //       this.onAccessTokenFetched(r.data.accessToken)
    //     })
    //   }
    //   const retryOriginalRequest = new Promise(resolve => {
    //     this.addSubscriber(accessToken => {
    //       // Make sure to assign accessToken according to your response.
    //       // Check: https://pixinvent.ticksy.com/ticket/2413870
    //       // Change Authorization header
    //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
    //       resolve(this.axiosIns(originalRequest))
    //     })
    //   })
    //   return retryOriginalRequest
    // }
    console.log(Vue.prototype)
    if (error.response.status === 500 || error.response.status === 500 || error.response.status === 404 || error.response.status === 400 || error.response.status === 415) {
      Vue.prototype.$toast.error('网络异常')
      // this.$toast('网络异常', {
      //     type: TYPE.ERROR,
      // })
    } else {
      Vue.prototype.$toast.error(`网络异常${ error.response.status}`)
 
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Redirect to login page
      router.push({
        path: '/login',
      })
      window.location.reload()
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
