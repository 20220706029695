<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="fs-40 text-bold">{{ title }}</div>
      <div class="fs-35">
        <countTo :startVal='startVal' :endVal='num' :duration='3000'></countTo>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  components: {
    countTo
  },
  props:{
    title:{
      type:String,
      default:function(){
        return ''
      }
    },
    num:{
      type:Number,
      default:function(){
        return ''
      }
    },
  },
  data() {
    return {
      startVal: 0,
      endVal: 20222,
    }
  },
  mounted() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';


// $box-width: 300px;
$box-width: 100%;

// $box-height: 410px;
$box-height: 200px;


#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 4rem;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>
