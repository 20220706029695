import request from '../request.js'

// 获取统计信息
export function getNotice(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getNotice',
    method: 'post',
    data,
  })
}

// 累计通行流量
export function getTotalCountByParkIds(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getTotalCountByParkIds',
    method: 'post',
    data,
  })
}

 // 今日流量
 export function getRecentlyDateBySeven(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getRecentlyDateBySeven',
    method: 'post',
    data,
  })
}

 // 今日总营收
 export function getTodayTran(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getTodayTran',
    method: 'post',
    data,
  })
}


// 累计总营收
export function getPayMoney(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getPayMoney',
    method: 'post',
    data,
  })
}

// 微信支付，支付宝，线下支付，ETC
export function getPayMoneyGroupType(data) {
  return request({
    url: '/park-base/parkBaseMonitor/getPayMoneyGroupType',
    method: 'post',
    data,
  })
}

// 获取支付数据
export function getLastPay(limit,data) {
  return request({
    url: `/park-base/parkBaseMonitor/getLastPay?limit=${limit}`,
    method: 'post',
    data,
  })
}

// 查看相机识别记录
export function save(data) {
  return request({
    url: '/park-base/feecase/appointment/save',
    method: 'post',
    data,
  })
}

// 查看相机识别记录
export function deleteById(id) {
  return request({
    url: `/park-base/feecase/appointment/deleteById/${id}`,
    method: 'delete'
  })
}
