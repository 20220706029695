<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex jc-center body-box">
        <!-- 轮播数据 -->
        <dv-scroll-board class="dv-scr-board" :config="config" style="width:100%;height:620px"/>
        <!-- /轮播数据 -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLastPay
} from '../utils/API/home.js'
export default {
  data() {
    return {
      parkIds:[],
      config: {
        header: ["<span  class='title'>车场</span>","<span  class='title'>车牌号</span>","<span  class='title'>金额</span>"],
        rowNum: 7, //表格行数
        headerHeight: 60,
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        align: ['center'],
        columnWidth:[300],
        // waitTime:9999999999999,
      },
      activeProjects:[], // 支付数据
    }
  },
  mounted() {
    const that = this
    that.parkIds = localStorage.getItem("parkIds")
    that.getLastPay()
    window.setInterval(() => {
      setTimeout(that.getLastPay(), 0)
      that.activeProjects = []
    }, 30000)
  },
  methods:{
    // 获取支付数据
    getLastPay() {
      getLastPay(20,JSON.parse(this.parkIds)).then(res => {
        if (res.data) {
          var newArr = [];
          newArr = res.data
          newArr.forEach(v => {
            this.activeProjects.push(
              [
                "<div class='parkName'><span class='name'>"+v.parkName+"</span><p class='time'>"+v.payTime+"</p></div>",
                "<div class='carNo'>"+v.carNo+"</div>",
              "<span  class='price'>"+v.payMoney+"元</span>"
              ]
            )
          })
          this.config = { 
            data: this.activeProjects,
            header: ["<span  class='title'>车场</span>","<span  class='title'>车牌号</span>","<span  class='title'>金额</span>"],
            rowNum: 7, //表格行数
            headerHeight: 60,
            oddRowBGC: '#0f1325', //奇数行
            evenRowBGC: '#171c33', //偶数行
            align: ['center'],
            columnWidth:[250],
            // waitTime:9999999999999,
          }
        }
      })
    },
  }
   
}
</script>

<style lang="scss" scoped>
$box-height: 680px;
// $box-width: 300px;
$box-width: 100%;

#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 100%;
      height: 340px;
    }
  }
}
::v-deep.dv-scroll-board .rows .ceil {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}


</style>
