<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex item">
        <div class="fs-40 text-bold">累计通行流量</div>
        <div class="fs-40">
          <countTo :startVal='startVal' :endVal='totalTrafficFlow' :duration='3000'></countTo>
        </div>
      </div>
      <div class="d-flex item">
        <div class="fs-40 text-bold">今日通行流量</div>
        <div class="fs-40">
          <countTo :startVal='startVal' :endVal='currentTrafficFlow' :duration='3000'></countTo>
        </div>
      </div>
      <div class="d-flex item">
        <div class="fs-40 text-bold">今日总营收</div>
        <div class="fs-40">
          <countTo :startVal='startVal' :endVal='moneyDay' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import {
  getTotalCountByParkIds,getRecentlyDateBySeven,getTodayTran
} from '../utils/API/home.js'
export default {
  components: {
    countTo
  },

  data () {
    return {
      parkIds:[],
      startVal: 0,
      // endVal: 2022,
      totalTrafficFlow:'', // 累计通行流量
      currentTrafficFlow:'', // 今日流量
      moneyDay:'', // 今日总营收



    }
  },
  mounted() {
    this.parkIds = localStorage.getItem("parkIds")
    this.getTotalCountByParkIds()
    this.getRecentlyDateBySeven()
    this.getTodayTran()
  },
  methods:{
    // 获取累计通行流量
    getTotalCountByParkIds() {
      console.log('获取临时支付渠道数据',this.parkIds)
      getTotalCountByParkIds(JSON.parse(this.parkIds)).then(res => {
        if (res.data) {
          this.totalTrafficFlow = res.data
          console.log('this.totalTrafficFlow',this.totalTrafficFlow)
        }
      })
    },
    // 今日流量
    getRecentlyDateBySeven() {
      getRecentlyDateBySeven(JSON.parse(this.parkIds)).then(res => {
        if (res.code === 0) {
          const sorteddataS = res.data.sort((a, b) => new Date(b.date) - new Date(a.date))
          this.currentTrafficFlow = sorteddataS[0].inCount || 0

        }
      })
    },

    // 今日总营收
    getTodayTran() {
      getTodayTran(JSON.parse(this.parkIds)).then(res => {
        if (res.code === 0) {
          this.moneyDay = ((res.data.payForCoupon ? Number(res.data.payForCoupon) : 0) + (res.data.payForMonthCard ? Number(res.data.payForMonthCard) : 0) + (res.data.payForParking ? Number(res.data.payForParking) : 0))

          console.log('今日总营收',this.moneyDay)

        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/scss/index.scss';

// $box-height: 520px;
// $box-height: 220px;
$box-height: 100%;


$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: 100%;
    // height: $box-height - 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 4rem;
  }
  .item{
    display: flex;
    flex-direction: column;
   
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
