<template>
  <!-- <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
      >
        <p class="ml-3 fw-b fs-40">累计总营收</p>
        <div>
          <div class="dv-dig-flop ml-1 mt-2 pl-3">
            <countTo :startVal='startVal' :endVal='endVal' :duration='3000'></countTo>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="flexs ai-center jc-between b">
    <div class="left">
      <div class="bg-color-black item border-r">
        <p class="ml-3 fw-b fs-40">累计总营收</p>
        <div class="dv-dig-flop ml-1 mt-40 pl-3 fs-35">
          <countTo :startVal='startVal' :endVal='accumulatedTotalRevenue||0' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
    </div>
    <div class="right ai-center jc-between">
      <div class="bg-color-black item border-r border-b" style="width: 50%;">
        <p class="ml-3 fw-b fs-40">微信支付</p>
        <div class="dv-dig-flop ml-1 mt-40 pl-3 fs-35">
          <countTo :startVal='startVal' :endVal='payMoneyGroupMoney.wx||0' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
      <div class="bg-color-black item border-b" style="width: 50%;">
        <p class="ml-3 fw-b fs-40">支付宝</p>
        <div class="dv-dig-flop ml- 1 mt-40 pl-3 fs-35">
          <countTo :startVal='startVal' :endVal='payMoneyGroupMoney.zfb||0' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
      <div class="bg-color-black item border-r" style="width: 50%;">
        <p class="ml-3 fw-b fs-40">线下支付</p>
        <div class="dv-dig-flop ml-1 mt-40 pl-3 fs-35">
          <countTo :startVal='startVal' :endVal='payMoneyGroupMoney.other||0' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
      <div class="bg-color-black item" style="width: 50%;">
        <p class="ml-3 fw-b fs-40">ETC</p>
        <div class="dv-dig-flop ml-1 mt-40 pl-3 fs-35">
          <countTo :startVal='startVal' :endVal='payMoneyGroupMoney.etc || 0' :duration='3000' :decimals="2"></countTo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import {
  getPayMoney,getPayMoneyGroupType
} from '../utils/API/home.js'
export default {
  components: {
    countTo
  },
  data() {
    return {
      parkIds:[],
      startVal: 0,
      endVal: 425662,
      accumulatedTotalRevenue:'', // 累计总营收
      payMoneyGroupMoney:'',

    }
  },
  mounted() {
    this.parkIds = localStorage.getItem("parkIds")
    this.getPayMoney()
    this.getPayMoneyGroupType()
  },
  methods:{
    // 获取累计总营收
    getPayMoney() {
      console.log('获取临时支付渠道数据',this.parkIds)
      getPayMoney(JSON.parse(this.parkIds)).then(res => {
        if (res.data) {
          this.accumulatedTotalRevenue = res.data.payMoney
          console.log('获取累计总营收',this.accumulatedTotalRevenue)
        }
      })
    },

    // 获取累计总营收,微信，支付宝等
    getPayMoneyGroupType() {
      getPayMoneyGroupType(JSON.parse(this.parkIds)).then(res => {
        if (res.code === 0){
          this.payMoneyGroupMoney = Object.assign({ wx: 0, other: 0, etc: 0, zfb: 0 }, res.data)
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 150px;
      .dv-dig-flop {
        // width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}


.left{
  flex: 2;
  
  
}


.right{
  display: flex;
  flex-wrap: wrap;
  flex: 4;
  align-items: center;
  justify-content: space-between;
}

.left .dv-dig-flop{
  // width: 200px;
}
.left .item{
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right .item{
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right .dv-dig-flop{
  // width: 200px;
}
</style>
